import React, {
  useState, FC, ReactNode, useEffect,
  useMemo,
} from 'react';
import _cloneDeep from 'lodash/cloneDeep';
import _find from 'lodash/find';
import {
  Card, Button, Form, Col, Row, Alert, Dropdown, ButtonGroup,
  ListGroup,
  Spinner,
} from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Collapse, Typography, StepConnector, StepLabel,
} from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

import { useHasFeatures } from 'hooks/useHasFeatures';
import { useHasRoles } from 'hooks/useHasRoles';
import { useHasPermissions } from 'hooks/useHasPermissions';
import { Input, Select, Textarea } from 'components/FormControls';
import SubmitButton from 'components/FormControls/SubmitButton';
import * as BroadcastFormApi from 'api/BroadcastFormAPI';
import {
  EventTemplateMode, TemplateAndEvent, TemplateOrEvent, EventType,
} from 'types/IEvent';
import { RootState } from 'types/rootState';
import { TemplateLabelDetail } from 'components/TemplateLabels/TemplateLabelDetail';
import { IEntityLabel } from 'types/IEntityLabel';
import { useAutosaveContext } from 'hooks/useAutosaveContext';
import { MarketingFields } from 'components/MarketingEmail/MarketingFields';
import { selectIsValidating } from 'reducers/Events';
import useSWR from 'swr';
import { selectBroadcastFormTypes } from 'reducers/UserInfo';
import { CommunicationPreferences } from './CommunicationPreferences';
import { DistributionList, ShowGroups } from './DistributionList';

type Props = {
  data: TemplateOrEvent
  eventTemplateMode?: EventTemplateMode
  onCancel: () => void
  isNew?: boolean
  occurrenceDate?: string
  enableGenerateSmartContent?: boolean
  onSubmit: (data: TemplateOrEvent) => void
};

interface SubmitButtonOption {
  label: string
  value: string
  type?: 'button' | 'reset' | 'submit'
  onClick?: () => void
}

const daysToRespondTooltip = `Sets a limit to how many days a recipient has to respond after first receiving the survey.
                    Leave empty to create a survey that never expires.
                    Changing this value does not retroactively apply to recipients who have already been sent this survey.`;
const submissionMessageTooltip = 'Optionally provide an additional message to display to recipients following submission of a survey response.';
const SubmitButtons = ({
  name,
  options: defaultOrderedOptions,
}: {
  name: string
  options: SubmitButtonOption[]
}): JSX.Element => {
  const {
    register, setValue, watch,
  } = useFormContext();
  const saving = useSelector((state: RootState) => state.Templates.loading);
  const validating = useSelector(selectIsValidating);
  const { isAutosaving } = useAutosaveContext();
  const submitValue = watch('submit');

  const options = (() => {
    if (!saving && !isAutosaving && !validating) {
      return defaultOrderedOptions;
    }

    const loadingOption = defaultOrderedOptions.find(isAutosaving
      ? (option) => option.value === 'save-as-draft'
      : (option) => option.value === submitValue,
    );

    if (!loadingOption) {
      throw new Error('Loading option not found');
    }

    const loadingOptionIdx = defaultOrderedOptions.indexOf(loadingOption);

    return [
      loadingOption,
      ...defaultOrderedOptions.slice(0, loadingOptionIdx),
      ...defaultOrderedOptions.slice(loadingOptionIdx + 1),
    ]
  })();

  const isLoading = saving || isAutosaving || validating;
  const isDisabled = isLoading;
  const isSaveComplete = !isLoading;

  const mainButtonJSX = (
    <SubmitButton
      label={options[0].label}
      id={`${options[0].value}-button`}
      variant="primary"
      savingLabel={options[0].label}
      className="ml-2"
      disabled={isDisabled}
      saveComplete={isSaveComplete}
      onClick={() => {
        setValue(name, options[0].value);
      }}
    />
  );

  return (
    <>
      <input type="hidden" ref={register} name={name} />
      {options.length > 1 ? (
        <Dropdown as={ButtonGroup}>
          <Dropdown.Toggle
            as={SubmitButton}
            type="button"
            label={isLoading ? options[0].label : 'Send Options'}
            id="send-options-button"
            variant="primary"
            savingLabel={options[0].label}
            className={clsx('ml-2', isLoading && 'isLoading')}
            disabled={isDisabled}
            saveComplete={isSaveComplete}
          />
          <Dropdown.Menu>
            {(isLoading
              ? options.slice(1)
              : options
            ).map((option) => (
              <Dropdown.Item
                key={option.value}
                disabled={isDisabled}
                as="button"
                type={option.type ?? 'submit'}
                onClick={option.onClick ?? (() => {
                  setValue(name, option.value);
                })}
              >
                {option.label}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      ) : mainButtonJSX}
    </>
  );
};

export const EventTemplateStepper: FC<Props> = ({
  data,
  eventTemplateMode = EventTemplateMode.Template,
  onCancel,
  onSubmit,
  isNew,
  occurrenceDate = '',
  enableGenerateSmartContent = false,
}) => {
  const {
    reset, control, errors, trigger, getValues, setValue, watch,
  } = useFormContext<TemplateAndEvent>();

  const [completedSteps, setCompletedSteps] = useState<{
    [key: number]: boolean
  }>(isNew ? { 1: false, 2: false, 3: false } : { 1: true, 2: true, 3: true });

  const canCreateCalendarAnnouncement = useHasPermissions('Calendar Announcement:Create');
  const hasReadMarketingPermission = useHasPermissions('Marketing:Send');
  const { hasFeature: hasMarketingFeature } = useHasFeatures('marketing-emails');
  const [isAdmin, isSuper] = useHasRoles(['Admin', 'Super']);

  const canScheduleEvent = canCreateCalendarAnnouncement || isAdmin || isSuper;
  const canReadMarketing = (hasReadMarketingPermission || isAdmin || isSuper) && hasMarketingFeature;
  const [updatedLabels, setUpdatedLabels] = useState<IEntityLabel[]>(null);

  const [currentStep, setCurrentStep] = useState<number>(0);

  const { enabled: isAutosavingEnabled } = useAutosaveContext();
  const formType = watch('FormType');
  const broadcastFormTypeRawOptions = useSelector(selectBroadcastFormTypes);
  const broadcastFormTypeOptions = useMemo(() => {
    if (!formType) return broadcastFormTypeRawOptions;
    return [...broadcastFormTypeRawOptions, { value: formType, label: formType }];
  }, [formType, broadcastFormTypeRawOptions])

  useEffect(() => {
    if (updatedLabels) {
      setValue('Labels', updatedLabels)
    } else if (data.Labels) {
      setValue('Labels', data.Labels);
    }
  }, [setValue, data, updatedLabels, setUpdatedLabels]);

  // trigger formtype validation on selection
  useEffect(() => {
    if (formType !== undefined) {
      trigger('FormType')
    }
  }, [formType, trigger]);

  let channelCaption = '';
  if (data.VoiceContent) {
    channelCaption += ' Voice ';
  }
  if (data.EmailContent) {
    channelCaption += ' Email ';
  }
  if (data.SMSContent) {
    channelCaption += ' Text Message ';
  }
  const [showProfiles, showGroups] = React.useMemo<[boolean, ShowGroups]>(() => {
    if (data?.EventType === 'compliance' || data?.EventType === 'lifedate') {
      return [false, 'special'];
    }
    return [true, 'basic'];
  }, [data?.EventType]);
  const { type: urlType } = useParams<{ type?: string }>();
  const templateType = urlType || 'template'
  const [stepperCaptionText, setStepperCaptionText] = useState<{
    [stepNum: number]: string
  }>(
    isNew
      ? {
        1: '',
        2: '',
        3: '',
      }
      : {
        1: data.Name,
        2: !showProfiles
          ? `Groups: ${data?.GroupIds?.length}`
          : `Groups: ${data?.GroupIds?.length} Profiles: ${data?.ProfileIds?.length}`,
        3: channelCaption,
      },
  );

  const isEvent: boolean = eventTemplateMode !== EventTemplateMode.Template;
  const isTemplate = eventTemplateMode === EventTemplateMode.Template;
  const isSurvey: boolean = isEvent && data.EventType === EventType.SURVEY;
  const isUrlLinkSurvey: boolean = isEvent && data.EventType === EventType.URLLINKSURVEY;
  const urlLinkSurveyCardClassName: string = isUrlLinkSurvey ? 'flex-direction-row' : '';
  const urlLinkSurveyRowClassName = isUrlLinkSurvey ? '12' : '6';

  const swrKey = ['getbroadcastformquestions', formType];
  const { data: broadcastFormQuestions, isLoading } = useSWR(
    swrKey,
    () => BroadcastFormApi.getFormQuestionsByFormType(formType),
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    });

  const handleSaveAsDraft = () => {
    const values = getValues();
    reset({
      ..._cloneDeep(values),
      submit: 'save-as-draft',
    });
    onSubmit({
      ...data,
      ...values,
      submit: 'save-as-draft',
    })
  }

  const renderSubmitButtons = (): JSX.Element => {
    let label: string;

    if (isTemplate) {
      label = 'Save';
    } else if (isSurvey || isUrlLinkSurvey) {
      label = 'Schedule';
    } else {
      label = 'Review';
    }

    const options: SubmitButtonOption[] = [
      {
        value: 'save',
        label,
      },
    ];

    if (
      isTemplate
      && templateType === 'template'
      && (IsMarketing ? canReadMarketing : canScheduleEvent)
    ) {
      options.push({
        value: 'schedule',
        label: 'Save & Schedule',
      });
    }

    if (isAutosavingEnabled) {
      const saveAsDraftOption: SubmitButtonOption = {
        value: 'save-as-draft',
        label: 'Save as draft',
        type: 'button',
        onClick: handleSaveAsDraft,
      }

      options.push(saveAsDraftOption);
    }
    return (
      <>
        <Button
          type="button"
          variant="btn mr-2 btn-link"
          onClick={onCancel}
        >
          Cancel
        </Button>

        <SubmitButtons
          name="submit"
          options={options}
        />
      </>
    );
  }

  type StepperValidationAndCaption = {
    validateStep: { (): Promise<boolean> }[]
    setCaptionText: { (): void }[]
  };

  const stepperValidObj: StepperValidationAndCaption = ({
    validateStep: [
      async () => {
        if (!isUrlLinkSurvey) {
          return trigger('Name');
        }
        return (await trigger('Name') && trigger('FormType') && trigger('DaysToRespond'));
      },
      async () => {
        if (!showProfiles) {
          return trigger('GroupIds');
        }
        return (await trigger('ProfileIds') || trigger('GroupIds'));
      }],
    setCaptionText: [
      () => {
        const nameValue = getValues('Name');
        setStepperCaptionText({
          ...stepperCaptionText,
          1: `Name: ${nameValue}`,
        });
      },
      () => {
        const profileValue = getValues('ProfileIds');
        const groupsValue = getValues('GroupIds');
        if (!showProfiles) {
          setStepperCaptionText({
            ...stepperCaptionText,
            2: `Groups: ${groupsValue?.length}`,
          });
          return;
        }
        setStepperCaptionText({
          ...stepperCaptionText,
          2: `Groups: ${groupsValue?.length} Profiles: ${profileValue?.length}`,
        });
      },
      () => {
        const voiceContentValue = getValues('VoiceContent.Content');
        const emailContentValue = getValues('EmailContent.Content');
        const smsContentValue = getValues('SMSContent.Content');
        const voiceChannel = voiceContentValue ? 'Voice' : '';
        const emailChannel = emailContentValue ? 'Email' : '';
        const smsChannel = smsContentValue ? 'SMS' : '';
        setStepperCaptionText({
          ...stepperCaptionText,
          3: `${voiceChannel} ${emailChannel} ${smsChannel}`,
        });
      }],
  });

  const handleStepChange = async (stepIndex: number) => {
    if (stepIndex === currentStep) {
      return;
    }
    if (stepIndex < currentStep || stepIndex === 0) {
      setCurrentStep(stepIndex);
      return;
    }

    const newCompletedSteps = {
      ...completedSteps,
    }
    const setCaptionPromises = [];

    let nextStep = currentStep;

    for (let step = currentStep; step < stepIndex; step++) {
      // eslint-disable-next-line no-await-in-loop
      const isValid = await stepperValidObj.validateStep[step]();

      if (!isValid) {
        newCompletedSteps[step] = false;
        break;
      }

      newCompletedSteps[step] = true;
      setCaptionPromises.push(stepperValidObj.setCaptionText[step]());
      nextStep++;
    }

    if (nextStep !== currentStep) {
      setCurrentStep(nextStep);
    }

    setCompletedSteps(newCompletedSteps);
    await Promise.all(setCaptionPromises)
  };

  const removeVoiceContent = () => {
    const currentFormData = getValues();
    const dataNoVoiceContent = {
      ...currentFormData,
      VoiceContent: null,
      voiceSelected: false,
      smsSelected: currentFormData.SMSContent != null,
      emailSelected: currentFormData.EmailContent != null,
    };
    // todo: should probably be doing this with setvalue and not reset.
    reset(dataNoVoiceContent);
  };
  const removeSmsContent = () => {
    const currentFormData = getValues();
    const dataNoSmsContent = {
      ...currentFormData,
      SMSContent: null,
      voiceSelected: currentFormData.VoiceContent != null,
      smsSelected: false,
      emailSelected: currentFormData.EmailContent != null,
    };
    // todo: should probably be doing this with setvalue and not reset.
    reset(dataNoSmsContent);
  };
  const removeEmailContent = () => {
    const currentFormData = getValues();
    const dataNoEmailContent = {
      ...currentFormData,
      EmailContent: null,
      voiceSelected: currentFormData.VoiceContent != null,
      smsSelected: currentFormData.SMSContent != null,
      emailSelected: false,
    };
    // todo: should probably be doing this with setvalue and not reset.
    reset(dataNoEmailContent);
  }

  const IsMarketing = data.IsMarketing ?? false;
  const steps: string[] = [
    'General Info',
    'Distribution List',
    IsMarketing
      ? 'Content'
      : 'Select Content',
  ];

  const entityName = IsMarketing
    ? 'marketing email'
    : 'template';

  return (
    <>
      <Stepper
        connector={(
          <StepConnector
            classes={{
              root: 'connectorRoot',
              line: 'connectorLine',
            }}
          />
        )}
        nonLinear
        activeStep={currentStep}
      >
        {steps.map((label, index) => {
          const buttonProps: { optional?: ReactNode } = {};
          buttonProps.optional = (
            <Typography variant="caption">{stepperCaptionText[index + 1]}</Typography>
          );
          return (
            <Step key={label}>
              <StepButton
                type="button"
                onClick={() => handleStepChange(index)}
                completed={completedSteps[index]}
                alternativeLabel
                style={label === 'Content' ? { minWidth: 100 } : {}}
                {...buttonProps}
              >
                <StepLabel
                  id={`steplabel-${label.replace(/\s/g, '')}`}
                  classes={{
                    active: 'activeLabel',
                    alternativeLabel: 'stepLabel',
                  }}
                  StepIconProps={{
                    classes: {
                      active: 'activeColor',
                    },
                  }}
                >
                  {`${label}`}
                </StepLabel>
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
      {isAutosavingEnabled && (
        <Alert variant="info" className="d-flex align-items-center">
          <FontAwesomeIcon icon="info-circle" className="mr-2" style={{ fontSize: '20px' }} />
          This
          {' '}
          {entityName}
          {' '}
          is a draft.
        </Alert>
      )}
      {errors?.selectedGroupAndProfiles && (
        <Alert variant="danger">Please select at least one profile or group to continue.</Alert>
      )}
      {errors?.VoiceContent && (
        <Alert variant="danger">Please review the issues found in the Voice Content.</Alert>
      )}
      {errors?.SMSContent?.Content && (
        <Alert variant="danger">Please review the issues found in the Text Message Content.</Alert>
      )}
      {errors?.EmailContent && (
        <Alert variant="danger">Please review the issues found in the Email Content.</Alert>
      )}
      {errors?.VoiceContent?.VoiceType && (
        <Alert variant="danger">Please select Text to Speech or Recording for Voice Content</Alert>
      )}
      {errors?.VoiceContent?.VoiceRecording?.Data && (
        <Alert variant="danger">Please select a file or record a message</Alert>
      )}
      {(errors?.voiceSelected || errors?.smsSelected || errors?.emailSelected) && (
        <Alert variant="danger">Please select at least one channel</Alert>
      )}
      <div className="m-1">
        {currentStep >= 1 && (
          <Button
            variant="secondary"
            onClick={() => {
              setCurrentStep(currentStep - 1);
            }}
          >
            {'<< Previous Step'}
          </Button>
        )}
        {currentStep < 2 && (
          <div className="float-right">
            <Button type="button" variant="btn mr-2 btn-link" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              variant="secondary"
              id="button-next"
              onClick={() => {
                handleStepChange(currentStep + 1);
              }}
            >
              {'Next Step >>'}
            </Button>
          </div>
        )}

        {currentStep === 2 && <div style={{ float: 'right' }}>{renderSubmitButtons()}</div>}
        <div style={{ clear: 'both' }} />
      </div>

      <Collapse in={currentStep === 0}>
        <Card className={`overflow-visible ${urlLinkSurveyCardClassName}`}>
          <Card.Body>
            {(errors?.SendTime || errors?.StartDate) && (
              <Alert variant="danger">Please schedule event for a time in the future</Alert>
            )}
            {errors?.RRule && (
              <Alert variant="danger">Please select at least one day when repeating weekly</Alert>
            )}
            <Row>
              <Col xs="12" md={urlLinkSurveyRowClassName}>
                <Form.Group>
                  <Input
                    highlights={['<Replace Text Here>']}
                    control={control}
                    type="Text"
                    className="inputMaxWidth"
                    id="template-name"
                    name="Name"
                    label="Name"
                    disabled={!isNew && isUrlLinkSurvey}
                    required
                    rules={{
                      required: 'The name is required',
                      validate: (val: string) => {
                        const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;
                        if (regex.test(val)) {
                          return 'Name can not include emojis';
                        }
                        return true;
                      },
                    }}
                    maxLength={50}
                    errors={errors.Name?.message}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md={urlLinkSurveyRowClassName}>
                <Form.Group>
                  <Input
                    highlights={['<Replace Text Here>']}
                    id="Description"
                    control={control}
                    type="Text"
                    className="inputMaxWidth"
                    name="Description"
                    label="Description"
                    disabled={!isNew && isUrlLinkSurvey}
                    rules={{
                      validate: (val: string) => {
                        const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;
                        if (regex.test(val)) {
                          return 'Description can not include emojis';
                        }
                        return true;
                      },
                    }}
                    maxLength={600}
                    errors={errors.Description?.message}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs="12" md={urlLinkSurveyRowClassName}>
                <Form.Group>
                  <Input
                    highlights={['<Replace Text Here>']}
                    id="Location"
                    className="inputMaxWidth"
                    control={control}
                    type="Text"
                    name="Location"
                    label="Location"
                    maxLength={200}
                    rules={{
                      validate: (val: string) => {
                        const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;
                        if (regex.test(val)) {
                          return 'Location can not include emojis';
                        }
                        return true;
                      },
                    }}
                    errors={errors.Location?.message}
                  />
                </Form.Group>
              </Col>
            </Row>
            {isUrlLinkSurvey && (
              <>
                <Row>
                  <Col xs="12" md={urlLinkSurveyRowClassName}>
                    <Form.Group>
                      <Select
                        id="FormType"
                        name="FormType"
                        label="Survey"
                        placeholder="Select"
                        className="selectInputPaddingRight"
                        disabled={!isNew}
                        required
                        control={control}
                        options={broadcastFormTypeOptions}
                        errors={errors.FormType?.message}
                        rules={{
                          required: 'Survey type is required',
                          validate: (val: string) => {
                            if (!_find(broadcastFormTypeOptions, (o) => o.value === val)) {
                              return 'Survey type is required';
                            }
                            return true;
                          },
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" md={urlLinkSurveyRowClassName}>
                    <Form.Group>
                      <Input
                        id="DaysToRespond"
                        name="DaysToRespond"
                        className="inputMaxWidth"
                        control={control}
                        type="text"
                        label="Days To Respond"
                        maxLength={3}
                        disabled={!isNew && isUrlLinkSurvey}
                        defaultValue={data.DaysToRespond}
                        rules={{
                          validate: (val: string) => {
                            if (val === '' || !val) {
                              return true;
                            }
                            const errMessage = 'Must be empty or number between 1 and 365';
                            try {
                              const intVal = parseInt(val, 10);
                              if (isNaN(intVal) || intVal > 365 || intVal < 1) return errMessage;
                            } catch (e) {
                              return errMessage;
                            }
                            return null;
                          },
                        }}
                        errors={errors.DaysToRespond?.message}
                        customIcon={(
                          <FontAwesomeIcon icon="question-circle" size="1x" />
                        )}
                        customIconTooltip={daysToRespondTooltip}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" md={urlLinkSurveyRowClassName}>
                    <Form.Group>
                      <Textarea
                        id="SubmissionMessage"
                        name="SubmissionMessage"
                        className="inputMaxWidth"
                        control={control}
                        label="Submission Message"
                        maxLength={600}
                        disabled={!isNew && isUrlLinkSurvey}
                        defaultValue={data.SubmissionMessage}
                        customIcon={(
                          <FontAwesomeIcon icon="question-circle" size="1x" />
                        )}
                        customIconTooltip={submissionMessageTooltip}
                        displayContentLength
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}
            {!isUrlLinkSurvey && (
              <Row>
                <Col xs="12" md="6">
                  <TemplateLabelDetail
                    labels={data.Labels}
                    onChange={(labels) => { setUpdatedLabels(labels); }}
                  />
                </Col>
              </Row>
            )}
          </Card.Body>
          {isUrlLinkSurvey && (
            <Card.Body className="questions-card">
              {(isLoading || broadcastFormQuestions == null) && (
                <div
                  className="loading-text d-flex align-items-center justify-content-center"
                  role="status"
                >
                  <Spinner
                    animation="border"
                    as="span"
                    className="mr-2"
                    style={{ width: '1.66em', height: '1.66em' }}
                  />
                  <span style={{ fontSize: '1.25em' }}>
                    Loading...
                  </span>
                </div>
              )}
              {!isLoading && broadcastFormQuestions !== null && formType
            && (
              <div style={{ height: '628.5px', overflowY: 'auto' }}>
                <p>
                  Survey Questions (
                  {broadcastFormQuestions?.length}
                  {' '}
                  total)
                </p>
                <ListGroup>
                  {broadcastFormQuestions.map((prof) => (
                    <ListGroup.Item
                      key={prof.QuestionId}
                      data-testid="message-recipient"
                      className="tableListItem"
                    >
                      {' '}
                      {prof.QuestionText}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </div>
            )}
            </Card.Body>
          )}

        </Card>
      </Collapse>
      <Collapse in={currentStep === 1}>
        <DistributionList
          disableValidation
          showProfiles={showProfiles}
          showGroups={showGroups}
        />
      </Collapse>

      <Collapse in={currentStep === 2}>
        <Card className="overflow-visible">
          <Card.Body>
            {IsMarketing ? (
              <MarketingFields
                isNew={isNew}
                data={data}
              />
            ) : (
              <CommunicationPreferences
                isSurvey={isSurvey}
                isUrlLinkSurvey={isUrlLinkSurvey}
                isNew={isNew}
                removeVoice={removeVoiceContent}
                removeSms={removeSmsContent}
                removeEmail={removeEmailContent}
                eventTemplateMode={eventTemplateMode}
                enableGenerateSmartContent={enableGenerateSmartContent}
                data={data}
              />
            )}
          </Card.Body>
        </Card>
      </Collapse>
    </>
  );
};
