import React, {
  ReactElement, useCallback, useState, useMemo, useEffect,
} from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { format } from 'date-fns/esm';
import { useHistory } from 'react-router-dom';
import { EventTemplateForm as EventStepper } from 'components/EventTemplateForm/EventTemplateForm';
import { EventSchedulerForm as EventScheduler } from 'components/EventScheduler';
import {
  INITIAL_STATE, createEvent, eventRequestStarted, eventRequestFinished, validateEventContent,
  selectIsValidating,
} from 'reducers/Events';
import { getUserState, selectCommunityName, selectInputLanguageDefaultValue } from 'reducers/UserInfo';
import {
  IEvent, EventType, ScheduleMode, EventTemplateMode,
} from 'types/IEvent';
import { CreateBroadcastForm } from 'types/BroadcastForm';
import * as BroadcastFormApi from 'api/BroadcastFormAPI';
import useSWRMutation from 'swr/mutation';
import CommunityNameWarningModal from 'pages/Templates/CommunityNameWarningModal';

export default function UrlLinkSurveyCreatePage(): ReactElement {
  const [showStepper, setShowStepper] = useState(true);
  const [surveyDataToSave, setSurveyDataToSave] = useState<IEvent|null>(null);
  const [showCommunityWarningModal, setShowCommunityWarningModal] = useState<boolean>(false);
  const validating = useSelector(selectIsValidating);
  const history = useHistory();
  const dispatch = useDispatch();
  const userInfo = useSelector(getUserState);
  const communityName = useSelector(selectCommunityName);
  const defaultInputLanguage = useSelector(selectInputLanguageDefaultValue);

  const initialData: IEvent = useMemo(() => ({
    ...INITIAL_STATE.selectedEvent,
    ID: undefined,
    EventType: EventType.URLLINKSURVEY,
    StartDate: format(new Date(), 'yyyy-MM-dd'),
    SendTime: moment().tz(userInfo.accountTimezone).add(5, 'minutes').format('HH:mm'),
    SendMode: ScheduleMode.Once,
    RRule: '',
    ExpirationTime: null,
    SourceLanguage: defaultInputLanguage,
    DaysToRespond: null,
    SubmissionMessage: null,
  }), [userInfo.accountTimezone]);

  const [data, setData] = useState(initialData);

  useEffect(() => {
    setData((oldData) => ({
      ...oldData,
      SendTime: moment().tz(userInfo.accountTimezone).add(5, 'minutes').format('HH:mm'),
    }));
  }, [userInfo.accountTimezone]);

  const saveData = useCallback((newData: Partial<IEvent>) => {
    setData((oldData) => ({
      ...oldData,
      ...newData,
    }));
  }, [setData]);

  const onSuccessCb = (stepperData: Partial<IEvent>) => {
    setShowStepper(false);
    saveData(stepperData);
  }

  const handleSubmitStepper = useCallback((formData) => {
    const surveyEvent: IEvent = {
      ...data,
      ...formData,
    }
    if (getShowCommunityNameWarning(surveyEvent)) {
      setShowCommunityWarningModal(true);
      setSurveyDataToSave(surveyEvent);
      return;
    }
    dispatch(validateEventContent([surveyEvent], 'urllinksurvey', () => {
      onSuccessCb(surveyEvent);
    }, (err) => {
    }));
  }, [saveData]);

  const addRecipientsByEventId = async (url, { arg }: { arg: { formId: number, eventId: number } }) => {
    dispatch(eventRequestStarted());
    await BroadcastFormApi.addRecipientsByEventId(arg.formId, arg.eventId);
  }
  const { trigger: addRecipients } = useSWRMutation('addrecipientsbyeventId', addRecipientsByEventId, {
    revalidate: true,
    populateCache: false,
    onSuccess: () => {
      dispatch(eventRequestFinished());
      toast.success('Survey successfully created');
      history.push('/feedback360');
    },
  });

  const createBroadcastForm = async (url, { arg }: { arg: IEvent }) => {
    const broadcastForm: CreateBroadcastForm = {
      Name: arg.Name,
      Description: arg.Description,
      FormType: arg.FormType,
      DaysToRespond: arg.DaysToRespond,
      SubmissionMessage: arg.SubmissionMessage,
    }

    dispatch(eventRequestStarted());
    const newForm = await BroadcastFormApi.addNewForm(broadcastForm);
    const newEvent = { ...arg, BroadcastFormId: newForm.FormId, IsBroadcastForm: true }
    return newEvent;
  }

  const onSuccessCbf = (surveyEvent: IEvent, event: IEvent) => {
    addRecipients({ formId: surveyEvent.BroadcastFormId, eventId: event.ID });
  }

  const { trigger: createForm } = useSWRMutation('createBroadcastForm', createBroadcastForm, {
    revalidate: true,
    populateCache: false,
    onSuccess: (surveyEvent: IEvent) => {
      dispatch(createEvent(surveyEvent, (event) => {
        onSuccessCbf(surveyEvent, event);
      }));
    },
  });

  const handleSubmitScheduler = useCallback((formData) => {
    const surveyEvent: IEvent = {
      ...data,
      ...formData,
    }
    createForm(surveyEvent);
  }, [data, dispatch, history]);

  const getShowCommunityNameWarning = (formData:IEvent) => {
    const {
      smsSelected, emailSelected,
    } = formData;

    const smsContentValue = formData.SMSContent?.Content
    const emailContentValue = formData.EmailContent?.Content
    const decodeEmailContentValue = emailContentValue ? atob(emailContentValue) : '';

    if (((smsSelected && smsContentValue && smsContentValue?.indexOf('$$CommunityName$$') !== -1)
      || (emailSelected && decodeEmailContentValue && decodeEmailContentValue?.indexOf('$$CommunityName$$') !== -1))
      && (communityName === null || communityName === '')) {
      return true;
    }
    return false;
  };

  return (
    <div className="TemplatesEdit">
      <Helmet>
        <title>Create Feedback360 Survey</title>
      </Helmet>
      <div className="header-bar">
        <h1>Create Feedback360 Survey</h1>
      </div>
      {showStepper ? (
        <>
          <EventStepper
            isNew
            data={data}
            eventTemplateMode={EventTemplateMode.Event}
            allowPreview={false}
            onSubmit={handleSubmitStepper}
            onCancel={() => history.push('/feedback360')}
          />
          <CommunityNameWarningModal
            entityName="Feedback360 Survey"
            showModal={showCommunityWarningModal}
            onHide={() => {
              setShowCommunityWarningModal(false);
            }}
            onSave={() => {
              setShowCommunityWarningModal(false);
              dispatch(validateEventContent([surveyDataToSave], 'urllinksurvey', () => {
                onSuccessCb(surveyDataToSave);
              }, (err) => {
              }));
            }}
            disableSavingButton={validating}
            setNameButtonLabel="Set name and Schedule"
            withoutSetNameButtonLabel="Schedule without setting"
          />
        </>
      ) : (
        <EventScheduler
          data={data}
          onSubmit={handleSubmitScheduler}
        />
      )}
    </div>
  );
}
